import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SocialButtons from "./SocialButtons";
import Servicemenu from "./Servicemenu";
import WebServiceMenu from "./Webservicemenu";
import { StaticImage } from "gatsby-plugin-image";

const Header = ({ siteTitle }) => {
  return (
    <header className="bg-dark">
      <Container fluid="xl">
        <Navbar bg="dark" sticky="top" variant="dark" expand="md">
          <Link to="/" aria-label="Home">
            <div className="logo"></div>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link to="/" className="nav-link">
                Домой
              </Link>
              <Link to="/about" className="nav-link">
                Обо&nbsp;мне
              </Link>
              <NavDropdown title="Услуги" id="collasible-nav-dropdown">
                <Container className="bg-dark pt-3 pb-3" fluid="lg">
                  <Row style={{ width: "100%" }}>
                    <Col
                      lg="4"
                      md="2"
                      sm="4"
                      className="d-none d-md-block pl-5 pb-5"
                    >
                      <StaticImage
                        src="../images/florian-klauer-mk7D-4UCfmg-unsplash-square.jpg"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        loading="eager"
                        alt="Photo by Florian Klauer on Unsplash"
                        draggable="false"
                        width={400}
                      />
                    </Col>
                    <Col lg="4" md="5" sm="12" className="pl-5 pb-3">
                      <h5 className="text-white pb-3">
                        Что&nbsp;могу&nbsp;предложить?
                      </h5>
                      <Servicemenu />
                    </Col>
                    <Col lg="4" md="5" sm="12" className="pl-5">
                      <h5 className="text-white pb-3">Веб-разработка</h5>
                      <WebServiceMenu />
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <Link to="/contacts" className="nav-link">
                Контакты
              </Link>
            </Nav>
            <SocialButtons />
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
