import React from "react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav";

const Menu = ({ menuItems }) => {
  return (
    <>
      <Nav className="flex-column">
        {menuItems.map((menuItem, index) => {
          return (
            <Link to={menuItem.link} className="nav-link" key={index}>
              {menuItem.name_ru}
            </Link>
          );
        })}
      </Nav>
    </>
  );
};

export default Menu;
