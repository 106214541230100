import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Mainmenu from "./Mainmenu";
import Servicemenu from "./Servicemenu";
import SocialButtons from "./SocialButtons";
import Thankyoumenu from "./Thankyoumenu";
import logoSrc from "../images/stamina-web-logo.png";
import { FaArrowUp } from "react-icons/fa";
import styled from "styled-components";

const Footer = ({ siteTitle }) => {
  return (
    <footer className="bg-dark">
      <Container fluid="lg">
        <Row className="p-5">
          <Col xs="6" sm="4">
            <MenuTitle className="text-white">Меню</MenuTitle>
            <Navbar variant="dark">
              <Mainmenu />
            </Navbar>
          </Col>
          <Col xs="6" sm="4">
            <MenuTitle className="text-white">Услуги</MenuTitle>
            <Navbar variant="dark">
              <Servicemenu />
            </Navbar>
          </Col>
          <Col xs="12" sm="4">
            <MenuTitle className="text-white">Соцсети</MenuTitle>
            <Navbar variant="dark">
              <SocialButtons />
            </Navbar>
            <MenuTitle className="text-white">Инфо</MenuTitle>
            <Navbar variant="dark">
              <Thankyoumenu />
            </Navbar>
          </Col>
        </Row>
        <Row>
          <Col
            xl="4"
            lg="4"
            md="6"
            sm="5"
            xs="12"
            className="p-5 text-md-right"
          >
            <a
              href="https://beget.com/?id=1127859"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://cp.beget.com/promo_data/static/static320x50_3.png"
                alt="beget"
                border="0"
                width="250"
                height="39"
                loading="lazy"
              />

              <img
                src="https://cp.beget.com/promo_data/static/static320x50_2.png"
                alt="beget"
                border="0"
                width="250"
                height="39"
                loading="lazy"
              />
            </a>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="6"
            sm="7"
            xs="12"
            className="p-5 text-md-right"
          >
            <Link to="/" className="logoname" aria-current="page">
              <img
                src={logoSrc}
                alt="StaminaWeb"
                width="300"
                height="auto"
                className="logosmall"
              />
            </Link>
            <div>
              <FaArrowUp className="display-4 text-secondary arrowmove" />
            </div>
          </Col>
        </Row>
        <Container fluid className="p-5 text-center">
          <Row>
            <Col>
              <div>
                <small className="text-light">
                  {siteTitle} © {new Date().getFullYear()}, <br />
                </small>
                <small className="text-light">
                  Этот веб-сайт с гордостью создан с помощью таких инструментов
                  и фреймворка: GatsbyJS, Node.js, GraphQL, Drupal и Visual
                  Studio Code.
                </small>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;

const MenuTitle = styled.div`
  font-size: 1.25rem;
  font-family: Oswald;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;
