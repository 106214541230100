import React from "react";
import Nav from "react-bootstrap/Nav";

import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillDribbbleCircle,
  AiFillGithub,
  AiFillLinkedin,
} from "react-icons/ai";

const socials = [
  {
    id: 1,
    icon: <AiFillFacebook className="react-icons" />,
    url: "https://www.facebook.com/andrey.bondarenko/",
    ariaLabel: "Facebook",
    active: true,
  },
  {
    id: 2,
    icon: <AiFillTwitterCircle className="react-icons" />,
    url: "https://twitter.com/staminaweb/",
    ariaLabel: "Twitter",
    active: true,
  },
  {
    id: 3,
    icon: <AiFillInstagram className="react-icons" />,
    url: "https://www.instagram.com/stamina.web/",
    ariaLabel: "Instagram",
    active: true,
  },
  {
    id: 4,
    icon: <AiFillDribbbleCircle className="react-icons" />,
    url: "https://dribbble.com/AndreyBond/",
    ariaLabel: "Dribble",
    active: true,
  },
  {
    id: 5,
    icon: <AiFillGithub className="react-icons" />,
    url: "https://github.com/andreybond79/",
    ariaLabel: "GitHub",
    active: true,
  },
  {
    id: 6,
    icon: <AiFillLinkedin className="react-icons" />,
    url: "ttps://www.linkedin.com/in/andrey-bondarenko79/",
    ariaLabel: "LinkedIn",
    active: true,
  },
];

const SocialButtons = () => {
  return (
    <Nav className="flex-row">
      {socials.map((social) => {
        const { id, icon, url, ariaLabel, active } = social;
        return (
          active && (
            <Nav.Link
              key={id}
              href={url}
              aria-label={ariaLabel}
              target="_blank"
              rel="noopener noreferrer"
            >
              {icon}
            </Nav.Link>
          )
        );
      })}
    </Nav>
  );
};

export default SocialButtons;
