import React from "react";

const HeaderUnderlined = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <h1
        className="animate__animated animate__slideInUp animate__faster"
        style={{ position: "relative" }}
      >
        {props.titlesrc}
      </h1>
      <div className="line002"></div>
      <br />
    </div>
  );
};

export default HeaderUnderlined;
