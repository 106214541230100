import React from "react";
import Menu from "./Menu";

const menuItems = [
  {
    link: "/thankyou",
    name_ru: "Спасибо",
  },
  {
    link: "/privacy",
    name_ru: "Политика ОПД",
  },
];
const Mainmenu = () => <Menu menuItems={menuItems} />;

export default Mainmenu;
