import React from "react";
import Menu from "./Menu";

const menuItems = [
  {
    link: "/",
    name_ru: "Домой",
  },
  {
    link: "/about",
    name_ru: "Обо мне",
  },
  {
    link: "/service",
    name_ru: "Услуги",
  },
  {
    link: "/contacts",
    name_ru: "Контакты",
  },
];
const Mainmenu = () => <Menu menuItems={menuItems} />;

export default Mainmenu;
