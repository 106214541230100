import React from "react";
import Menu from "./Menu";

const menuItems = [
  {
    link: "/landingpage",
    name_ru: "Целевая страница",
  },
  {
    link: "/blog",
    name_ru: "Сайт-блог",
  },
  {
    link: "/webpage",
    name_ru: "Веб-сайт",
  },
  {
    link: "/web-portfolio",
    name_ru: "Веб портфолио",
  },
];
const Mainmenu = () => <Menu menuItems={menuItems} />;

export default Mainmenu;
