import React from "react";
import Menu from "../components/Menu";

const menuItems = [
  {
    link: "/web-design",
    name_ru: "Веб дизайн",
  },
  {
    link: "/photography",
    name_ru: "Фотография",
  },
  {
    link: "/videography",
    name_ru: "Видеография",
  },
  {
    link: "/support",
    name_ru: "Поддержка",
  },
];
const Mainmenu = () => <Menu menuItems={menuItems} />;

export default Mainmenu;
